import Swiper from 'swiper';
import { Pagination, Navigation } from 'swiper/modules';

export default function () {

    performance.mark("Swiper module starts")

    const cs = getComputedStyle(document.documentElement);

    //MAIN BANNER
    const mainBanner = new Swiper('.main-banner', {
        modules: [Pagination],
        preloadImages: false,
        //autoplay: {
        //    delay: 1000
        //},
        lazy: false,
        loop: false,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: true
        },
        on: {
            beforeTransitionStart: function (instance) {

                const currentSlide = instance.slides[instance.activeIndex];
                const slideImg = currentSlide.querySelector('img');

                if (slideImg.hasAttribute('src')) return;

                currentSlide.classList.add('loaded');
                slideImg.setAttribute('src', slideImg.getAttribute('data-src'));
            }
        }
    });

    //MAIN PAGE PRODUCTS
    const breakpoints = {
        1: {},
        360: {},
        601: {},
        769: {},
        1280: {}
    };

    const slider1 = new Swiper('.slider-with-products.slider1 .swiper', {
        modules: [Navigation],
        preloadImages: false,
        navigation: {
            nextEl: '.swiper-button-next.slider-button1',
            prevEl: '.swiper-button-prev.slider-button1'
        },
        loop: false,
        breakpoints: breakpoints,
        slidesPerView: Number(cs.getPropertyValue('--prodSwiperCols')),
        spaceBetween: Number(cs.getPropertyValue('--prodGapHor').replace('px', '')),
    });

    slider1.on('breakpoint', function () {
        slider1.params.slidesPerView = Number(cs.getPropertyValue('--prodSwiperCols'));
        slider1.params.spaceBetween = Number(cs.getPropertyValue('--prodGapHor').replace('px', ''));
        slider1.update();
    });

    const slider2 = new Swiper('.slider-with-products.slider2 .swiper', {
        modules: [Navigation],
        preloadImages: false,
        navigation: {
            nextEl: '.swiper-button-next.slider-button2',
            prevEl: '.swiper-button-prev.slider-button2'
        },
        loop: false
    });

    const slider3 = new Swiper('.slider-with-products.slider3 .swiper', {
        modules: [Navigation],
        preloadImages: false,
        navigation: {
            nextEl: '.swiper-button-next.slider-button3',
            prevEl: '.swiper-button-prev.slider-button3'
        },
        loop: false,
        breakpoints: breakpoints,
        slidesPerView: Number(cs.getPropertyValue('--prodSwiperCols')),
        spaceBetween: Number(cs.getPropertyValue('--prodGapHor').replace('px', '')),
    });

    slider3.on('breakpoint', function () {
        slider3.params.slidesPerView = Number(cs.getPropertyValue('--prodSwiperCols'));
        slider3.params.spaceBetween = Number(cs.getPropertyValue('--prodGapHor').replace('px', ''));
        slider3.update();
    });

    //PRODUCERS
    const producersBanner = new Swiper('.producers-banner .swiper', {
        modules: [Navigation],
        preloadImages: false,
        navigation: {
            nextEl: '.swiper-button-next.producer-button',
            prevEl: '.swiper-button-prev.producer-button'
        },
        loop: false,
        spaceBetween: 61,
        breakpoints: {
            1: {
                slidesPerView: 3,
                spaceBetween: 15
            },
            601: {
                slidesPerView: 3
            },
            1100: {
                slidesPerView: 4
            },
            1280: {
                slidesPerView: 5
            },
            1681: {
                slidesPerView: 6
            }
        }
    });

    performance.mark("Swiper module ends")

}